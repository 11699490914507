module.exports = [{
      plugin: require('../plugins/gatsby-fixr-cms-plugin/gatsby-browser.tsx'),
      options: {"plugins":[],"siteId":"site-home-leeds-co-uk","baseUrl":"https://home-leeds.co.uk","name":"Home Leeds","adminRoute":"/admin","templatePath":"/workspace/src/templates","logo":{"src":"/logo.png","alt":"Home Leeds","width":250,"height":52}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
